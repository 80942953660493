import './App.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import { MAPBOX_API_KEY } from './config';

import sommets from './data/sommets.json';
import lacs from './data/lacs.json';

import { getStorage, setStorage } from './utils';

import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import { useEffect, useRef, useState } from 'react';

mapboxgl.accessToken = MAPBOX_API_KEY;

const bounds = {
  desktop: [
    [6.632124338563614, 41.33037839372997], // Southwest coordinates
    [11.402475661439183, 43.07832982448602]  // Northeast coordinates
  ],
  mobile: [
    [8.259870556040994, 40.98465629453338], // Southwest coordinates
    [9.77472944395899, 43.41281141955241]  // Northeast coordinates
  ],
}

let timeout = null;

const INITIAL_ZOOM = '7.50';
const INITIAL_LNG = 9.0425;
const INITIAL_LAT = 42.0808;

function App() {

  const mapContainer = useRef(null);
  const map = useRef(null);

  // centered on whole Corsica
  const [lng, setLng] = useState(INITIAL_LNG);
  const [lat, setLat] = useState(INITIAL_LAT);
  const [zoom, setZoom] = useState(INITIAL_ZOOM);

  const [loaded, setLoaded] = useState(false);

  const [summits, setSummits] = useState(sommets);
  const [lakes, setLakes] = useState(lacs);

  const [open, setOpen] = useState(false);

  const [filteredIds, setFilteredIds] = useState([]);
  const [clickedCoords, setClickedCoords] = useState(null);

  const [menu, setMenu] = useState('filter');
  const [subMenu, setSubMenu] = useState(null);

  const [mySummits, setMySummits] = useState(getStorage('mySummits', []));
  const [myLakes, setMyLakes] = useState(getStorage('myLakes', []));

  const onCloseMenu = () => {
    setOpen(false);
    setMenu('filter');
    setSubMenu(null);
  };

  useEffect(() => {
    if (map.current) return; // initialize map only once

    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/guillaumepotier/clfsi9zmf00m501mxv05vuk9z',
      center: [lng, lat],
      zoom: zoom
    });

    // jail map to Corsica
    map.current.setMaxBounds(window.screen.availWidth < 768 ? bounds.mobile : bounds.desktop);

    // disable map rotation using right click + drag
    map.current.dragRotate.disable();

    // disable map rotation using touch rotation gesture
    map.current.touchZoomRotate.disableRotation();

    map.current.on('load', () => setLoaded(true));

    map.current.on('move', () => {
      setLng(map.current.getCenter().lng.toFixed(4));
      setLat(map.current.getCenter().lat.toFixed(4));
      setZoom(map.current.getZoom().toFixed(2));
    });

    console.log(map.current.getBounds());
  }, [map]);

  useEffect(() => {
    if (!loaded) return;

    if (map.current.getLayer("summits"))
      map.current.removeLayer("summits");

    if (map.current.getSource("summits"))
      map.current.removeSource("summits");

    if (map.current.getLayer("lakes"))
      map.current.removeLayer("lakes");

    if (map.current.getSource("lakes"))
      map.current.removeSource("lakes");

    map.current.addSource('summits', {
      type: 'geojson',
      data: summits,
    });

    map.current.addSource('lakes', {
      type: 'geojson',
      data: lakes,
    });

    map.current.addLayer({
      id: 'summits',
      type: 'circle',
      source: 'summits',
      // green circles with white border
      paint: {
        'circle-color': 'darkgreen',
        'circle-radius': 10,
        'circle-stroke-width': 2,
        'circle-stroke-color': '#fff'
      },
    });

    map.current.addLayer({
      id: 'lakes',
      type: 'circle',
      source: 'lakes',
      // blue circles with white border
      paint: {
        'circle-color': 'blue',
        'circle-radius': 10,
        'circle-stroke-width': 2,
        'circle-stroke-color': '#fff'
      },
    });

    map.current.on('click', ['lakes', 'summits'], (e) => {
      if (!e.features || e.features.length === 0) return;

      const coordinates = e.features[0].geometry.coordinates.slice();
      const name = e.features[0].properties.name;
      const altitude = e.features[0].properties.altitude;
      const massif = e.features[0].properties.massif;

      // Ensure that if the map is zoomed out such that
      // multiple copies of the feature are visible, the
      // popup appears over the copy being pointed to.
      while (Math.abs(e.lngLat.lng - coordinates[0]) > 180)
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;

      new mapboxgl.Popup()
        .setLngLat(coordinates)
        .setHTML(`Nom: ${name}<br/>Altitude: ${altitude}<br/>Massif: ${massif}`)
        .addTo(map.current);
    });

    // dump map bounds on click
    map.current.on('click', (event) => {

      // console.log event lngLat
      console.log(event.lngLat);
      setClickedCoords(event.lngLat);

      // console.log bounds
      console.log(map.current.getBounds());

      onCloseMenu();
    });

  }, [loaded, summits]);

  useEffect(() => {
    if (!loaded) return;

    const newSummits = {
      type: "FeatureCollection",
      features: [],
    };

    if (filteredIds.length === 0) {
      setSummits(sommets);
      return;
    }

    for (let i = 0; i < sommets.features.length; i++) {
      const { properties } = sommets.features[i];

      if (filteredIds.includes(properties.id)) {
        newSummits.features.push(sommets.features[i]);
      }
    }

    setSummits(newSummits);
  }, [filteredIds]);

  useEffect(() => {
    if (timeout)
    clearTimeout(timeout);

    timeout = setTimeout(() => {
      setClickedCoords(null);
    }, 5000);
  }, [clickedCoords]);

  return (
    <div className="App">

      <div className='Modal' style={{
        display: open ? 'flex' : 'none',
        flexDirection: 'column',
        position: 'absolute',
        zIndex: 10,
        maxWidth: '90vw',
        maxHeight: '80vh',
        minWidth: '300px',
        padding: '20px',
        transform: 'translate(-50%, -50%)',
        top: '50%',
        left: '50%',
        justifyContent: 'center',
        alignItems: 'center',
        background: '#fff',
        borderRadius: '10px',
      }}>

        {menu === 'filter' && (
          <>
            <strong style={{ marginBottom: "15px" }}>Filtrer les sommets {filteredIds.length > 0 ? `(${filteredIds.length})` : null}</strong>

            <div className="buttons">

              {mySummits.length > 0 && (
                <>
                  <button
                    onClick={() => {
                      setSubMenu(null);
                      setFilteredIds(mySummits);
                    }}
                  >
                    Afficher mes sommets
                  </button>
                  <button
                    onClick={() => {
                      setSubMenu(null);

                      // filter geojon.features to only keep those not in mySummits
                      const filtered = sommets.features.filter(({ properties }) => !mySummits.includes(properties.id));

                      // map only an array of ids
                      const filteredIds = filtered.map(({ properties }) => properties.id);

                      setFilteredIds(filteredIds);
                    }}
                  >
                    Afficher mes sommets restants
                  </button>
                </>
              )}

              <button onClick={() => setSubMenu(subMenu === 'indiv' ? null : 'indiv')}>Afficher certains sommets</button>
              {filteredIds.length > 0 && <button onClick={() => setFilteredIds([])}>Afficher tous les sommets</button>}
            </div>

            {subMenu === 'indiv' && (
              <ul style={{ height: "80%", overflow: "auto", listStyle: "none" }}>
                {sommets.features.map(({ properties }, i) => (
                  <li style={{ textAlign: "left" }} key={i}>
                    <input type="checkbox" checked={filteredIds.includes(properties.id)} onChange={(e) => {
                      if (e.target.checked) {
                        setFilteredIds([...filteredIds, properties.id]);
                      } else {
                        setFilteredIds(filteredIds.filter((id) => id !== properties.id));
                      }
                    }
                    } />
                    {properties.name} ({properties.altitude}m)
                  </li>
                ))}
              </ul>
            )}

            <a className="link" onClick={() => setMenu('mysummits')}>Gérer mes sommets ({mySummits.length})</a>
          </>
        )}

        {menu === 'mysummits' && (
         <>
            <strong>Mes sommets</strong>

            <ul style={{ height: "80%", overflow: "auto", listStyle: "none" }}>
              {sommets.features.map(({ properties }, i) => (
                <li style={{ textAlign: "left" }} key={i}>
                  <input type="checkbox" checked={mySummits.includes(properties.id)} onChange={(e) => {
                    const newSummits = e.target.checked ? [...mySummits, properties.id] : mySummits.filter((id) => id !== properties.id)
                    setMySummits(newSummits);
                    setStorage('mySummits', newSummits);
                  }} />
                  {properties.name} ({properties.altitude}m)
                </li>
              ))}
            </ul>

            <a className="link" onClick={() => setMenu('filter')}>Retour</a>
         </>
        )}

      </div>

      <div className="sidebar">
        {!clickedCoords && (<span>Longitude: {lng} | Latitude: {lat} | Zoom: {zoom}</span>)}
        {clickedCoords && (
          <span onClick={() => setTimeout(async () => {
            try {
              await navigator.clipboard.writeText(`${clickedCoords.lng},${clickedCoords.lat}`);
              alert('Copied to clipboard!');
            } catch (err) {}
          }, 100)}>Long: {clickedCoords.lng} | Lat: {clickedCoords.lat}
          </span>)}
      </div>

      <div ref={mapContainer} className="map-container" />

      <div
        className='Menu'
        onClick={() => {
          setOpen(!open);
          setMenu('filter');
          setSubMenu(null);
        }}
      >
        {filteredIds.length > 0 && <div className="red-dot" />}
        <img src="/filter.svg" height="24px" width="24px" />
      </div>

      {zoom !== INITIAL_ZOOM && (
        <div className="Reset" onClick={() => {
          map.current.easeTo({
            center: [INITIAL_LNG, INITIAL_LAT],
            zoom: INITIAL_ZOOM,
          });
        }}>Reset</div>
      )}

    </div>
  );
}

export default App;
