export const getStorage = (key, placeholder) => {
  try {
    const value = localStorage.getItem(key);
    return value ? JSON.parse(value) : placeholder;
  } catch (err) {
    return placeholder;
  }
};

export const setStorage = (key, value) => {
  try {
    localStorage.setItem(key, JSON.stringify(value));
  } catch (err) {}
};